































import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'ModalFiltersProfile',

  props: {
    foodTypesFilters: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      filters: []
    }
  },

  computed: {
    ...mapState('common', [
      'businessFilters'
    ]),

    businessFiltersFoodTypes () {
      return this.businessFilters?.food_type
    }
  },

  methods: {
    onClickAddFilters () {
      this.$bvModal.hide('modal-filters-profile')
      this.$emit('add-chosen-filters', this.filters)
    },

    onClickChooseFilter (filter) {
      if (this.filters.some(f => f.id === filter.id)) {
        this.filters = this.filters.filter(f => f.id !== filter.id)
      } else {
        this.filters.push(filter)
      }
    },

    onShownModal () {
      this.filters = [...this.foodTypesFilters]
    }
  }
})
