















































import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'ModalProfileImage',

  props: {
    bgImage: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      image: null,
      imageUrl: null,
      loading: false
    }
  },

  methods: {
    ...mapActions('common', [
      'uploadMedia'
    ]),

    handleFileUpload () {
      this.image = this.$refs.file.files[0]
      if (this.image.size > 1058576) {
        this.$toast.error('Максимальный размер обложки 1МБ')
      } else {
        this.imageUrl = URL.createObjectURL(this.image)
      }
    },

    async onClickSaveImage () {
      this.loading = true
      const formData = new FormData()
      formData.append('file_to_upload', this.image)

      try {
        const res = await this.uploadMedia(formData)
        this.$emit('bg-image-url', res.url)
      } catch {
        this.$toast.error('Произошла ошибка при загрузке фотографии')
      } finally {
        this.loading = false
        this.$bvModal.hide('modal-profile-image')
      }
    }
  }
})
