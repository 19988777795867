

















































































































































































































































































































































































































































































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import Schedule from '@/components/Schedule/Schedule.vue'
import { TheMask } from 'vue-the-mask'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormMaskedInput from '@/components/ui/ValidatedBFormMaskedInput.vue'
import { Profile } from '@/store/modules/profiles/types'
import { cloneDeep } from '@/utils/functions'
import ModalPickAddress from '@/components/ModalPickAddress/ModalPickAddress.vue'
import ModalProfileImage from '@/views/ProfileDetail/components/ModalProfileImage.vue'
import ModalFiltersProfile from '@/views/ProfileDetail/components/ModalFiltersProfile.vue'
import { isEqual, omit } from 'lodash'
// import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'

export default Vue.extend({
  name: 'ProfileDetail',

  components: {
    TheMask,
    Loading,
    ValidatedBFormInput,
    ValidatedBFormMaskedInput,
    Schedule,
    ModalPickAddress,
    ModalProfileImage,
    ModalFiltersProfile
    // VueTelInput
  },

  data () {
    return {
      saving: false,
      localProfile: null as Profile,
      localProfileCopyTemplate: null as Profile,
      scheduleIsValid: true,
      deletedBusinessWorkTimesIds: [],
      isShownAddressModal: false,
      changingAddressKey: '',
      changingLocation: null,
      contactPhoneCode: null,
      contactPhoneData: {
        valid: true
      },
      foodTypesFilters: [],
      isProfileUnsaved: false
    }
  },

  computed: {
    ...mapState('common', [
      'businessCategories',
      'currencyItems',
      'loading',
      'businessFilters'
    ]),

    ...mapState('profiles', [
      'profile'
    ]),

    businessFiltersFoodTypes () {
      return this.businessFilters?.food_type || []
    },

    isBusinessProfile () {
      return this.localProfile?.profile_type === 'business'
    },

    name () {
      return this.isBusinessProfile ? 'Название бизнеса' : 'Имя'
    }
  },

  watch: {
    profile () {
      this.setLocalProfile()
    },

    localProfile: {
      handler (value) {
        this.isProfileUnsaved = !isEqual(
          omit(value, ['profile_data.work_time_schedule', 'profile_data.contact_phone']),
          omit(this.localProfileCopyTemplate, ['profile_data.work_time_schedule', 'profile_data.contact_phone'])
        )
      },
      deep: true
    }
  },

  mounted () {
    if (this.profile?.id) {
      this.setLocalProfile()
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.isProfileUnsaved) {
      this.$bvModal.show('unsaved-profile-modal')
    } else {
      next()
    }
  },

  methods: {
    ...mapActions('profiles', [
      'updateClientProfile',
      'updateBusinessProfile',
      'deleteBusinessWorkTimes',
      'updateDeliveryLocation'
    ]),

    async onSubmit () {
      // this.localProfile.profile_data.contact_phone = this.localProfile.profile_data.contact_phone.replaceAll(/[ +]/g, '')

      const isValid = await this.$refs.observer.validate()

      if (!isValid || !this.scheduleIsValid || !this.contactPhoneData?.valid) return

      this.handleUpdateProfile()
    },

    async handleUpdateProfile () {
      if (this.$refs.schedule) {
        const schedule = this.$refs.schedule.returnSchdule()
        if (this.localProfile.profile_type === 'business' && schedule.weekends.length) {
          await this.removeBusinessWorkTimes(schedule.weekends)
        }
      }
      if (this.localProfile.profile_data.location) {
        await this.handleUpdateDeliveryLocation()
      }

      await this.updateProfileData()
    },

    async handleUpdateDeliveryLocation () {
      try {
        this.saving = true
        await this.updateDeliveryLocation({
          id: this.localProfile.profile_data.id,
          byField: 'business_profile_id',
          data: this.localProfile.profile_data.location
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async removeBusinessWorkTimes (workTimesIds) {
      try {
        this.saving = true
        await this.deleteBusinessWorkTimes({
          profileId: this.localProfile.profile_data.id,
          workTimesIds
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async updateProfileData () {
      const updateMethod = this.localProfile.profile_type === 'client' ? 'updateClientProfile' : 'updateBusinessProfile'

      const data = cloneDeep(this.localProfile)
      if (data?.profile_data?.contact_phone) {
        data.profile_data.contact_phone = data.profile_data.contact_phone.replaceAll(/[ +]/g, '')
      }
      // if (data?.profile_data?.average_check) {
      //   data.profile_data.average_check = data?.profile_data?.average_check.length
      // }
      data.profile_data.food_type = this.foodTypesFilters.map(f => {
        return { id: f.id, name: f.name }
      })
      this.saving = true
      const result = await this[updateMethod](data)
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail)
        this.saving = false
        return
      }

      this.$toast.success('Данные обновлены')
      this.saving = false
    },

    setLocalProfile () {
      this.localProfile = cloneDeep(this.profile)
      // if (this.localProfile?.profile_data?.average_check) {
      //   this.localProfile.profile_data.average_check = '$'.repeat(this.localProfile.profile_data.average_check)
      // }
      if (this.localProfile?.profile_data?.food_type?.length) {
        this.foodTypesFilters = this.businessFiltersFoodTypes.filter((foodType: { id: string }) =>
          this.localProfile?.profile_data?.food_type.some((profileFoodType: { id: string }) => foodType.id === profileFoodType.id))
      }
      if (this.localProfile?.profile_data?.rating && Number.isInteger(this.localProfile?.profile_data?.rating)) {
        this.localProfile.profile_data.rating = this.localProfile.profile_data.rating.toFixed(1)
      }
      if (this.localProfile?.profile_data?.commission_percentage && Number.isInteger(this.localProfile?.profile_data?.commission_percentage)) {
        this.localProfile.profile_data.commission_percentage = this.localProfile.profile_data.commission_percentage.toFixed(1)
      }
      this.localProfileCopyTemplate = cloneDeep(this.localProfile)
    },

    handleSaveAddress (location) {
      this.localProfile.profile_data.location = location
      this.isShownAddressModal = false
    },
    handleCloseAddress () {
      this.changingAddressKey = ''
      this.isShownAddressModal = false
    },
    handleOpenAddress (key) {
      this.changingLocation = this.localProfile.profile_data[key]
      this.changingAddressKey = key
      this.isShownAddressModal = true
    },
    handleChangedSchedule (schedule) {
      this.localProfile.profile_data.work_time_schedule = schedule
    },

    validateContactPhone (...data) {
      const phoneObject = data.length > 1 ? data[1] : data[0]
      const number = data.length > 1 ? data[0] : ''
      this.contactPhoneData = { ...phoneObject, valid: phoneObject.valid || true }
      if (phoneObject.number && phoneObject.number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
      if (number && number.match(/[^\d+\s]/)) {
        this.contactPhoneData.valid = false
      }
      if (this.contactPhoneData.formatted && this.contactPhoneData.valid) {
        this.localProfile.profile_data.contact_phone = this.contactPhoneData.number
      }
    },

    validateMainPhone (data) {
      if (data.number) {
        this.localProfile.phone = data.number
      }
    },

    onClickOpenFilters () {
      this.$bvModal.show('modal-filters-profile')
      this.isShownFiltersModal = true
    },

    onClickRemoveFilter (id) {
      this.foodTypesFilters = this.foodTypesFilters.filter(f => f.id !== id)
    }
  }
})
